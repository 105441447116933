import { render, staticRenderFns } from "./loginNow.vue?vue&type=template&id=4ddba538&scoped=true"
import script from "./loginNow.vue?vue&type=script&lang=js"
export * from "./loginNow.vue?vue&type=script&lang=js"
import style0 from "./loginNow.vue?vue&type=style&index=0&id=4ddba538&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../auth-app-ui/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ddba538",
  null
  
)

export default component.exports