const runningInAndroidEmulator = false;
const runningInIosEmulator = false;

let baseConf = {
    local: {
        KX_BASE: runningInAndroidEmulator && ["4008", "4009"].includes(window.location.port) ? 'http://10.49.0.4:1399' : 'http://localhost:1399',
        DX_BASE: runningInAndroidEmulator && ["4008", "4009"].includes(window.location.port) ? 'http://10.49.0.4:1401' : runningInIosEmulator ? "http://" + getLocalIP() + "1401" : 'http://localhost:1401',
        BX_BASE: runningInAndroidEmulator && ["4008", "4009"].includes(window.location.port) ? 'http://10.49.0.4:1337' : runningInIosEmulator ? "http://" + getLocalIP() + "1337" : 'http://localhost:1337',
        EX_BASE: runningInAndroidEmulator && ["4008", "4009"].includes(window.location.port) ? 'http://10.49.0.4:1507' : runningInIosEmulator ? "http://" + getLocalIP() + "1507" : 'http://localhost:1507',

        KX_UI: 'http://localhost:4006',
        BX_UI: 'http://localhost:3003',
        BI_UI: 'http://localhost:4009',
        DW_UI: 'http://localhost:3004',
        DSI_UI: 'http://localhost:4007',

        INC_UI: 'http://localhost:4011',
        KNX_UI: 'http://localhost:4008',
        BET_UI: 'http://localhost:4002',
        BI_DASH: 'http://localhost:4010',
        INC_UI: 'http://localhost:4011',
        GMU_UI: 'http://localhost:4038',
        TSM_UI: 'http://localhost:4040',
    },
    prod: {
        KX_BASE: 'https://kx.prd.kennectweb.com',
        EX_BASE: 'https://gp1.ex.prd.kennectweb.com',

        KX_UI: 'https://acc.prd.kennectweb.com',
        BX_UI: 'https://api.prd.kennectweb.com',
        DW_UI: 'https://dw.prd.kennectweb.com',
        DSI_UI: 'https://dsi.prd.kennectweb.com',
        KNX_UI: 'https://knx.prd.kennectweb.com',
        BET_UI: 'https://bet.prd.kennectweb.com',
        // BET_UI: 'http://localhost:4002',
        BI_DASH: 'https://bi-render.prd.kennectweb.com',
        BI_UI: "https://bi.prd.kennectweb.com",
        INC_UI: "https://inc.prd.kennectweb.com",

        GMU_UI: 'https://gmu.prd.kennectweb.com',
        TSM_UI : "https://tsm.prd.kennectweb.com"
    },
    prodx: {
        // rename it to prod if u want t point to stg
        KX_BASE: 'https://kx.stg.kennectweb.com',
        BX_BASE: 'https://bx.stg.kennectweb.com',
        DX_BASE: 'https://dx.stg.kennectweb.com',
        /* UI end-points */
        KX_UI: 'https://acc.stg.kennectweb.com',
        BX_UI: 'https://api.stg.kennectweb.com',
        DW_UI: 'https://dw.stg.kennectweb.com',
        DSI_UI: 'https://dsi.stg.kennectweb.com',
        INC_UI: 'https://inc.stg.kennectweb.com',
        KNX_UI: 'https://knx.stg.kennectweb.com',
        BET_UI: 'https://bet.stg.kennectweb.com',
    },
    stg: {
        KX_BASE: 'https://kx.stg.kennectweb.com',
        BX_BASE: 'https://bx.stg.kennectweb.com',
        DX_BASE: 'https://dx.stg.kennectweb.com',
        EX_BASE: 'https://ex.stg.kennectweb.com',
        /* UI end-points */
        KX_UI: 'https://acc.stg.kennectweb.com',
        BX_UI: 'https://api.stg.kennectweb.com',
        DW_UI: 'https://dw.stg.kennectweb.com',
        DSI_UI: 'https://dsi.stg.kennectweb.com',
        INC_UI: 'https://inc.stg.kennectweb.com',
        KNX_UI: 'https://knx.stg.kennectweb.com',
        BET_UI: 'https://bet.stg.kennectweb.com',
        BI_UI: 'https://bi.staging.kennectweb.com',
    },
    l2prod_local: {
        KX_BASE: 'https://kx.prd.kennectweb.com',
        BX_BASE: 'https://bx.prd.kennectweb.com',
        DX_BASE: 'https://dx.prd.kennectweb.com',

        KX_UI: 'http://localhost:4006',
        BX_UI: 'http://localhost:3003',
        DW_UI: 'http://localhost:3004',
        DSI_UI: 'http://localhost:4007',
        KNX_UI: 'http://localhost:4008',
        BET_UI: 'http://localhost:4002',
    },
    dev: {
        KX_BASE: 'https://kx.dev.kennectweb.com',
        BX_BASE: 'https://bx.dev.kennectweb.com',
        DX_BASE: 'https://dx.dev.kennectweb.com',
        EX_BASE: 'https://ex.dev.kennectweb.com',

        KX_BASE: 'https://kx.dev.kennectweb.com',
        KX_UI: 'https://acc.dev.kennectweb.com',
        BX_UI: 'https://api.dev.kennectweb.com',
        DW_UI: 'https://dw.dev.kennectweb.com',
        DSI_UI: 'https://dsi.dev.kennectweb.com',
        KNX_UI: 'https://knx.dev.kennectweb.com',
        BET_UI: 'https://bet.dev.kennectweb.com',
        INC_UI: 'https://inc.dev.kennectweb.com',
        BI_UI: 'https://bi.dev.kennectweb.com',
        BI_DASH: 'https://bi-render.dev.kennectweb.com',
        GMU_UI: 'https://gmu.dev.kennectapi.com',
        TSM_UI: 'https://tsm.dev.kennectapi.com',

        // KENNECTAPI.COM
        // KX_BASE: 'https://kx.dev.kennectapi.com',
        // BX_BASE: 'https://bx.dev.kennectapi.com',
        // DX_BASE: 'https://dx.dev.kennectapi.com',
        // EX_BASE: 'https://ex.dev.kennectapi.com',

        // KX_UI: 'https://acc.dev.kennectapi.com',
        // BX_UI: 'https://api.dev.kennectapi.com',
        // DW_UI: 'https://dw.dev.kennectapi.com',
        // DSI_UI: 'https://dsi.dev.kennectapi.com',
        // KNX_UI: 'https://knx.dev.kennectapi.com',
        // BET_UI: 'https://bet.dev.kennectapi.com',
        // BI_UI: 'https://bi.dev.kennectapi.com',
        // BI_DASH: 'https://bi-render.dev.kennectapi.com',
    },
    dev_alpha: {
        KX_BASE: 'https://kx.alpha.kennectapi.com',
        BX_BASE: 'https://bx.alpha.kennectapi.com',
        DX_BASE: 'https://dx.alpha.kennectapi.com',
        EX_BASE: 'https://ex.alpha.kennectapi.com',

        KX_UI: 'https://acc.alpha.kennectapi.com',
        BX_UI: 'https://api.dev.kennectapi.com',
        DW_UI: 'https://dw.alpha.kennectapi.com',
        DSI_UI: 'https://dsi.alpha.kennectapi.com',
        INC_UI: "https://inc.alpha.kennectweb.com",
        KNX_UI: 'https://knx.alpha.kennectapi.com',
        BET_UI: 'https://bet.alpha.kennectapi.com',
        BI_UI: 'https://bi.alpha.kennectapi.com',
        BI_DASH: 'https://bi-render.alpha.kennectapi.com',
    },
    l2dev_local: {
        KX_BASE: 'https://kx.dev.kennectapi.com',
        BX_BASE: 'https://bx.dev.kennectapi.com',
        DX_BASE: 'https://dx.dev.kennectapi.com',
        EX_BASE: 'https://ex.dev.kennectapi.com',

        KX_UI: 'http://localhost:4006',
        BX_UI: 'http://localhost:3003',
        DW_UI: 'http://localhost:3004',
        DSI_UI: 'http://localhost:4007',
        KNX_UI: 'http://localhost:4008',
        BET_UI: 'http://localhost:4002',
        BI_UI: 'http://localhost:4009',
        BI_DASH: 'http://localhost:4010',

    }
}

try {
    baseConf = require('./conf.override')(baseConf);
    // create a conf.override js file in this dir and as shown in override.sample.js 
} catch (ignorableIssue) { }

let modeApplied = null;
let applyMode = (value) => {
    console.log('[kx-sdk/config.applyMode] setting modeApplied', { currentModeApplied: modeApplied, newValue: value });
    if (!value) {
        let buildInfo = require('./getBuildInfo')();
        // console.log('[kx-sdk] using getBuildInfo() for baseSdkConf');
        modeApplied = buildInfo.buildMode;
    } else {
        if (modeApplied && modeApplied != value) {
            console.log('[Showing error in browser console]')
            console.error('[kx-sdk/config.applyMode] Warning: existing modeApplied doesnt match nw value, it is being changed', { modeApplied, value })
        }
        modeApplied = value;
        throw "should not change mode to apply as of now"
    }
    // console.log('[kx-sdk/config.applyMode] fixed modeApplied', { currentModeApplied: modeApplied, newValue: value });
}
let getSdkConf = () => {
    if (!modeApplied) {
        console.warn('[kx-sdk/config.applyMode] Warning: Using modeApplied before appliying: will apply auto')
        console.log('[kx-sdk/config.applyMode]  Warning: Using modeApplied before appliying: will apply auto')
        applyMode();
        // throw "please set the modeApplied"
    }
    let config = baseConf[modeApplied];
    config.modeApplied = modeApplied;
    return config;
};
module.exports = {
    applyMode,
    getSdkConf
};
